import React from "react";
// Customizable Area Start
import { appLogo, notificationIcon, peoplesIcon, searchIcon, userIcon } from "../../../blocks/landingpage/src/assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { OutlinedInput, InputAdornment, Button, Grid } from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";
import './HeaderMain.web.css';
// Customizable Area End

interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
}
  
// Customizable Area Start
interface S { }

interface SS { }
// Customizable Area End

export default class HeaderMain extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDrawer = () => {
    const sidebarmain = document.querySelector('#sidebarMain');
    const backShadowEle = document.querySelector('.header-shadow');
    sidebarmain?.classList.add('open');
    setTimeout(() => backShadowEle?.classList.add('active'),500);
  }

  closeDrawer = (e:any) => {
    const sidebarmain = document.querySelector('#sidebarMain');
    sidebarmain?.classList.remove('open');
    e.target.classList.remove('active');
  }

  // componentDidMount(): any {
  //   const searchBlock = document.querySelector('.header-container .search-wrapper');
  //   if(window.location.pathname != '/home') {
  //     searchBlock?.setAttribute('style','display:none');
  //   }
  // }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="header-container">
        <div className="logo-wrapper">
          <Button className="toggle-btn" onClick={() => this.handleDrawer()}>
            <MenuIcon />
          </Button>
          <img src={appLogo} alt="Momentum" />
        </div>

        <Grid container className="action-container">
          {/* <div className="search-wrapper">
            <OutlinedInput
              id="search-bar"
              className="search-bar"
              placeholder="Search Influencers"
              endAdornment = {
                <InputAdornment position="end" className="search-addon">
                  <img src={searchIcon} alt="" width={"25px"}/>
                </InputAdornment>
              }
            />
          </div> */}

          <div className="header-right-wrapper">
            <Button className="users-btn">
              <img src={peoplesIcon} alt="Users" width={"25px"}/>
            </Button>
            <Button className="notification-btn">
              <img src={notificationIcon} alt="notifications" width={"25px"}/>
            </Button>
            <div className="user-info">
              <div className="user-icon"><img src={userIcon} alt="" width={"25px"}/></div>
              <span>Username</span>
            </div>
          </div>
        </Grid>

        <div className="header-shadow" onClick={(e) => this.closeDrawer(e)}></div>
      </div>
      // Customizable Area End
    );
  }
}
