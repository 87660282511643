import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import HeaderMain from "../../../components/src/Common/HeaderMain.web";
import SidebarMain from "../../../components/src/Common/SidebarMain.web";
import HomepageController, { Props } from "./HomepageController.web";
import { OutlinedInput, InputAdornment, Grid } from '@material-ui/core';
import { searchIcon } from "./assets";
import '../assets/css/HomePage.web.css';
// Customizable Area End

export class HomepageWeb extends HomepageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div id="Main">
        <HeaderMain/>
        <div className="middle-container">
          <SidebarMain/>

          <div className="content-wrapper home-page">
            <h1 className="page-title">Hi, Username.</h1>
            <h1 className="page-subtitle">Find the influencers that match your needs</h1>
            <div className="search-field">
              <OutlinedInput
                id="search-input"
                className="search-input"
                placeholder="Search Influencers"
                endAdornment = {
                  <InputAdornment position="end" className="search-addon">
                    <img src={searchIcon} alt="" width={"25px"}/>
                  </InputAdornment>
                }
              />
            </div>
            <Grid container className="boxes-container">
              <div className="data-box"></div>
              <div className="data-box"></div>
              <div className="data-box"></div>
              <div className="data-box"></div>
              <div className="data-box"></div>
              <div className="data-box"></div>
            </Grid>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}
// @ts-ignore
export default withRouter(HomepageWeb);
