import react from "react";

export const focusInput = (elmnt: any) => {
    const optInputs: any = document.querySelectorAll('.otpInput');

    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
        const next = elmnt.target.tabIndex - 2;
        if (next > -1 && optInputs[next]) {
            optInputs[next].focus();
        }
    } else {
        const next = elmnt.target.tabIndex;
        if (next < 6 && optInputs[next] && elmnt.key !== "Enter") {
            optInputs[next].focus();
        }
    }
}

export default focusInput;
