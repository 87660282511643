import React from "react";
// Customizable Area Start
import {
  contactIcon,
  discoveryIcon,
  helpIcon,
  homeIcon,
  logoutIcon,
  settingIcon,
  sidebarToggleIcon
} from "../../../blocks/landingpage/src/assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { withRouter } from 'react-router-dom';
import { Link, Button } from '@material-ui/core';
import './HeaderMain.web.css';
// Customizable Area End

interface Props {
  navigation?: any;
  id?: string;
  history?: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }
// Customizable Area End

class SidebarMain extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  toggleMenu = (e: any) => {
    const targetEle = e.target.parentNode;
    targetEle.classList.toggle('hidden');
  }

  navigateTo = (path: string) => {
    if (window.location.pathname === path) {
      return;
    }
    this.props.history.push(path);
  }

  handleLogout = () => {
    localStorage.clear();
    this.props.history.push('/');
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const currPage = window.location.pathname;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="sidebar-container" id="sidebarMain">

        <Link
          component="button"
          underline="none"
          className={`nav-link ${currPage.includes('Home') && 'active'}`}
          onClick={() => this.navigateTo('/Home')}
        >
          <img src={homeIcon} alt="" width={"22px"} />
          <span>Home</span>
        </Link>

        <Link
          component="button"
          underline="none"
          className={`nav-link ${currPage.includes('instagramSearch') && 'active'}`}
        >
          <img src={discoveryIcon} alt="" width={"22px"} />
          <span>Discovery & Outreach</span>
        </Link>

        <div className="sub-nav-list">
          <span className="links-head" onClick={(e) => this.toggleMenu(e)}>campaign management</span>
          <ul>
            <li>
              <Link
                component="button"
                underline="none"
                className="sub-link"
              >
                campaigns
              </Link>
            </li>
            <li>
              <Link
                component="button"
                underline="none"
                className="sub-link"
              >
                media plan
              </Link>
            </li>
          </ul>
        </div>

        <div className="sub-nav-list">
          <span className="links-head" onClick={(e) => this.toggleMenu(e)}>Market Trend</span>
          <ul>
            <li>
              <Link component="button" underline="none" className="sub-link">most viewed youtube videos</Link>
            </li>
            <li>
              <Link component="button" underline="none" className="sub-link">ER benchmark</Link>
            </li>
            <li>
              <Link component="button" underline="none" className="sub-link">top trending accounts</Link>
            </li>
            <li>
              <Link component="button" underline="none" className="sub-link">top quality accounts</Link>
            </li>
            <li>
              <Link component="button" underline="none" className="sub-link">most mentioned accounts</Link>
            </li>
          </ul>
        </div>

        <Link
          component="button"
          underline="none"
          className={`nav-link ${currPage.includes('helpAndFaq') && 'active'}`}
          onClick={() => this.navigateTo('/helpAndFaq')}
        >
          <img src={helpIcon} alt="" width={"22px"} />
          <span>help</span>
        </Link>

        <Link 
          component="button" 
          underline="none" 
          className={`nav-link ${currPage.includes('settings') && 'active'}`}
          onClick={() => this.navigateTo('/settings')}
        >  
          <img src={settingIcon} alt="" width={"22px"} />
          <span>setting</span>
        </Link>

        <Link
          component="button"
          underline="none"
          className={`nav-link ${currPage.includes('contactus') && 'active'}`}
          onClick={() => this.navigateTo('/contactus')}
        >
          <img src={contactIcon} alt="" width={"22px"} />
          <span>contact us</span>
        </Link>

        <Link
          component="button"
          underline="none"
          className="nav-link"
          onClick={() => this.handleLogout()}
        >
          <img src={logoutIcon} alt="" width={"22px"} />
          <span>logout</span>
        </Link>
      </div>
      // Customizable Area End
    );
  }
}
// @ts-ignore
export default withRouter(SidebarMain);
