import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import HeaderMain from "../../../components/src/Common/HeaderMain.web";
import SidebarMain from "../../../components/src/Common/SidebarMain.web";
import {
    Tab,
    Button,
    Dialog,
    IconButton,
    Box,
    Grid
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import CloseIcon from '@material-ui/icons/Close';
import '../assets/css/AccountSetting.web.css';
import AccountSettingsController, { Props } from "./AccountSettingsController.web";
import { warningIcon,userimg } from "./assets";
// Customizable Area End

export class AccountSettingWeb extends AccountSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <div id="Main">
                <HeaderMain />
                <div className="middle-container" >
                    <SidebarMain />
                    <div className="content-wrapper account-setting">
                        <h2 className="page-title">Settings</h2>

                        <TabContext value={this.state.value}>
                            <TabList onChange={this.handleChange} className="setting-tabs">
                                <Tab label="Account" value="1" className="tab-button" />
                                <Tab label="Team" value="2" className="tab-button" />
                                <Tab label="Activity" value="3" className="tab-button" />
                                <Tab label="Subscription" value="4" className="tab-button" />
                            </TabList>

                            <TabPanel value="1" className="Account-tabview" id="tab1">
                                <div className="wrapper-row">
                                    <Box className="profile-details-box">
                                        <h2 className="box-head">Profile details</h2>
                                        <div className="img-actions">
                                            <img src={this.state.user_image ? this.state.user_image : userimg} />
                                            <label htmlFor="user_profile" className="btn-secondary btn-outlined">upload image</label>
                                            {this.state.user_image && <Button id="uploadbtn" className="btn-secondary" onClick={(e) => this.removeimage(e)}>remove</Button>}

                                            <input
                                                type="file"
                                                hidden
                                                accept="images/*"
                                                id="user_profile"
                                                className="user-profile-input"
                                                onChange={(event: any) => {
                                                    if (event.target.files[0]) {
                                                        this.custURL(event.target.files[0])
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Grid container className="profile-form">

                                            <div className="field">
                                                <label htmlFor="Email">Email</label>
                                                <div>
                                                    <input type="email" value={this.state.getaccount.email} id="email" name="email" placeholder="Enter your new Email" disabled />
                                                    {this.state.getaccount.is_email_verified ? <span className="input-action verified">verified</span> : <span onClick={() => this.sendEmailOtp()} className="input-action">verify</span>}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Box>

                                </div>
                                <Box className="close-acc-box">
                                    <h2 className="box-head">Close Account</h2>
                                    <span className="action-info">
                                        You can Permenantly close account this step is not reversable.
                                    </span>
                                    <Button className="Btn-secondary" id="btnedit" onClick={() => this.setState({ open: true })}>
                                        Close account
                                    </Button>
                                </Box>
                            </TabPanel>

                            <TabPanel value="4" className="subscription-tabview">
                                <Grid container className="plan-info">
                                    <div className="col">
                                        <span className="info-head">Subscription</span>
                                        <span className="info">{this.state.subscriptionType}</span>
                                    </div>
                                    <div className="col">
                                        <span className="info-head">Created on</span>
                                        <span className="info" dangerouslySetInnerHTML={{__html: this.state.subscriptionStart}}></span>
                                    </div>
                                    <div className="col">
                                        <span className="info-head">Valid till</span>
                                        <span className="info" dangerouslySetInnerHTML={{__html: this.state.subscriptionEnd}}></span>
                                    </div>
                                </Grid>
                            </TabPanel>
                         
                        </TabContext>
                    </div>
                </div>


                <Dialog aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="confirm-dialog"
                    open={this.state.open}
                    closeAfterTransition
                    maxWidth="lg"
                >
                    <IconButton
                        aria-label="close"
                        className="close-btn"
                        id="closebtn"
                        onClick={() => this.setState({ open: false })}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h2 className="dialog-title">
                        <img src={warningIcon} alt="" width={'25px'} />
                        <span>Are you sure ?</span>
                    </h2>
                    <p>You can permenantly close account this step <br /> is not reversable</p>

                    <div className="dialog-actions">
                        <Button
                            className="btn-secondary btn-outlined"
                            onClick={() => this.setState({ open: false })}
                            id="btnnon"
                        >
                            Cancel
                        </Button>
                        <Button className="btn-secondary" id="deletebtn" onClick={(e) => this.closeAccount(e)}>Delete Account</Button>
                    </div>

                </Dialog>

                <Dialog
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="otp-dialog"
                    open={this.state.emailpopup}

                    closeAfterTransition
                    maxWidth="lg"
                >
                    {/* <OtpVerificationWeb navigation={undefined} push={undefined} match={undefined} /> */}
                    <>
                        <h2 className="dialog-title">verification</h2>
                        <p className="dialog-txt">Enter the verification code we just sent you <br /> on your email address</p>
                        <div className="otp-input-row">
                            <input
                                id="OTPInput1"
                                name="otp1"
                                type="text"
                                autoFocus
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp1}
                                onChange={(e) => this.setState({ otp1: e.target.value })}
                                tabIndex={1}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            />
                            <input
                                id="OTPInput2"
                                name="otp2"
                                type="text"
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp2}
                                onChange={(e) => this.setState({ otp2: e.target.value })}
                                tabIndex={2}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            />
                            <input
                                id="OTPInput3"
                                name="otp3"
                                type="text"
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp3}
                                onChange={(e) => this.setState({ otp3: e.target.value })}
                                tabIndex={3}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            />
                            <input
                                id="OTPInput4"
                                name="otp4"
                                type="text"
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp4}
                                onChange={(e) => this.setState({ otp4: e.target.value })}
                                tabIndex={4}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            />

                            <input
                                id="OTPInput5"
                                name="otp5"
                                type="text"
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp5}
                                onChange={(e) => this.setState({ otp5: e.target.value })}
                                tabIndex={5}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            />
                            <input
                                id="OTPInput6"
                                name="otp6"
                                type="text"
                                autoComplete="off"
                                className="otpInput"
                                value={this.state.otp6}
                                onChange={(e) => this.setState({ otp6: e.target.value })}
                                tabIndex={6}
                                maxLength={1}
                                onKeyUp={e => this.inputFocus(e)}
                            // onKeyUp={e => this.inputFocus(e)}
                            />
                        </div>
                        {this.state.otpError && <div className="validation-msg">{this.state.otpError}</div>}
                        <p className="dialog-txt">
                            if you didn't receive a code!
                            <span
                                onClick={() => this.handleResendOtp()}>resend</span>
                            {this.state.countdown > 0 && <span className="count-down">00:{this.state.countdown}</span>}
                        </p>
                        <div className="dialog-actions">
                            <Button className="btn-secondary" id="verifybtn" onClick={(e) => this.verifyOtp(e)}>Verify</Button>
                        </div>
                    </>
                </Dialog>
            </div>
            // Customizable Area End
        );
    }
}
//@ts-ignore
export default withRouter(AccountSettingWeb);