import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import { Grid, Button } from "@material-ui/core";
import "../../mobile-account-registration/assets/css/login.css";
import {
    VerifyOtpHeader,
    VerifyOtpSidebar
} from "../../../components/src/Common/verifyOtpCommon.web";
import { focusInput } from "../../../components/src/Common/FocusInput.web";
// Customizable Area End
import MobileAccountLoginController, { Props } from "./MobileAccountLoginController.web";

export class VerifyMobileLogin extends MobileAccountLoginController {

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        this.startCountdown();
        if (this.props.location.state) {
            const { mobile, otpToken } = this.props.location.state;
            this.setState({ mobileNo: mobile });
            this.setState({ otpToken: otpToken });
        }
        if(localStorage.getItem('purl')) {
            clearInterval(this.timer);
            this.setState({countdown: 0}, () => {
                localStorage.removeItem("purl");
            });
        }
    }

    async componentWillUnmount(): Promise<void> {
        clearInterval(this.timer);
    }

    render() {

        return (
            // Customizable Area Start
            <div id="Main">
                <VerifyOtpHeader />
                <Grid container xs={12} className="verification-auth" id="Main">
                    <VerifyOtpSidebar />
                    <Grid item xs={12} sm={12} md={6} className="auth-form-container">
                        <h2>Verify OTP</h2>
                        <span className="sub-head">Please enter the 6 digit OTP sent to<br></br> phone no XXXXXXXXXX</span>
                        <span className="redtext" id="changeNo" onClick={() => this.handleChangeNo()}>Change number</span>

                        <form id="otp-form">
                            <div className="otp-input-row">
                                <input
                                    id="otpInput1"
                                    name="otp1"
                                    type="text"
                                    autoFocus
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp1}
                                    onChange={(e) => this.setState({ otp1: e.target.value })}
                                    tabIndex={1}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />
                                <input
                                    id="otpInput2"
                                    name="otp2"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp2}
                                    onChange={(e) => this.setState({ otp2: e.target.value })}
                                    tabIndex={2}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />
                                <input
                                    id="otpInput3"
                                    name="otp3"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp3}
                                    onChange={(e) => this.setState({ otp3: e.target.value })}
                                    tabIndex={3}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />
                                <input
                                    id="otpInput4"
                                    name="otp4"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp4}
                                    onChange={(e) => this.setState({ otp4: e.target.value })}
                                    tabIndex={4}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />

                                <input
                                    id="otpInput5"
                                    name="otp5"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp5}
                                    onChange={(e) => this.setState({ otp5: e.target.value })}
                                    tabIndex={5}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />

                                <input
                                    id="otpInput6"
                                    name="otp6"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={this.state.otp6}
                                    onChange={(e) => this.setState({ otp6: e.target.value })}
                                    tabIndex={6}
                                    maxLength={1}
                                    onKeyUp={e => focusInput(e)}
                                />
                            </div>
                            {this.state.otpError && <div className="validation-msg">{this.state.otpError}</div>}
                            <div className="form-actions">
                                <div className="resend-otp">
                                    <Button
                                        className="btn-resend"
                                        id="resend-otp-btn"
                                        disabled={this.state.countdown > 0}
                                        onClick={() => this.handleResendOtp()}
                                    >resend</Button>
                                    {this.state.countdown > 0 && <span className="count-down">00:{this.state.countdown}</span>}
                                </div>
                                <Button type="submit" className="btn-secondary submit-otp" onClick={(e) => this.verifyOtp(e)}>Submit</Button>
                            </div>
                        </form>
                        <span className="redtext bottom-action" onClick={() => this.handleNeedhelp()}>Need help?</span>
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        );
    }
}
// @ts-ignore
export default withRouter(VerifyMobileLogin);
