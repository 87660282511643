import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall, apiCallwithHeader } from "../../../components/src/Apicall";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  history?: any;
  push: any;
  location?: any;
  match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allQueAns: any;
  allQueAnsMsg: any,
  detailed_QueAns: any,
  searchQuery: any,
  is_error: boolean,
  is_HeaderSidebar: boolean,
  userId: string,
  isLiked: boolean | null,
  currQueId: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HelpAndFaqController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllQueAnsApiCallId: any;
  getQueDetailsApiCallId: any;
  searchQueryApiCallId: any;
  getUserAccountApiCallId: any;
  likeApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      allQueAns: [],
      allQueAnsMsg: "",
      detailed_QueAns: "",
      searchQuery: "",
      is_error: false,
      is_HeaderSidebar: true,
      userId: "",
      isLiked: null,
      currQueId: "",
    };

    this.handleAccordien = this.handleAccordien.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    if (this.props.location.state) {
      this.setState({ is_HeaderSidebar: false });
      this.getAllQueAns();
    } else {
      this.getUserAccount();
    }
    if (this.state.searchQuery.trim().length === 0) {
      this.getAllQueAns();
    }
  }

  async componentWillUnmount() {
    if(this.props.location.state) {
      localStorage.setItem("purl", window.location.pathname);
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (this.getAllQueAnsApiCallId === apiRequestCallId) {
        this.getAllQueApiProcess(responseJson);
      }

      if (this.getQueDetailsApiCallId === apiRequestCallId) {
        this.getQueDetailsApiProcess(responseJson);
      }

      if (this.searchQueryApiCallId === apiRequestCallId) {
        this.searchQueryApiProcess(responseJson);
      }

      if (this.getUserAccountApiCallId === apiRequestCallId) {
        this.getUserAccountApiProcess(responseJson);
      }

      if (this.likeApiCallId === apiRequestCallId) {
        this.handleLikeApiProcess(responseJson);
      }
    }
    // Customizable Area End 
  }

  // Customizable Area Start
  getQueDetailsApiProcess = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ 
        detailed_QueAns: responseJson.data?.attributes,
        isLiked: responseJson.data?.attributes?.is_liked 
      });
    }
  }

  getUserAccountApiProcess = (responseJson:any) => {
    if (responseJson.data) {
      this.setState({ userId: responseJson.data.id }, () => {
        this.getAllQueAns();
      });
    }
  }

  getAllQueApiProcess = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ allQueAns: [] }, () => {
        this.setState({
          allQueAns: responseJson.data,
          detailed_QueAns: responseJson.data[0]?.attributes
        });
      });
      if(window.innerWidth > 768) {
        this.setState({isLiked: responseJson.data[0]?.attributes?.is_liked});
      }
    }
    this.checkApiErrors(responseJson);
  }

  searchQueryApiProcess = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ allQueAns: [] }, () => {
        this.setState({ allQueAns: responseJson.data });
      });
    }
    this.checkApiErrors(responseJson);
  }

  checkApiErrors = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ allQueAnsMsg: responseJson.errors[0]?.message, is_error: true });
    }
  }

  handleLikeApiProcess = (responseJson: any) => {
    if (!responseJson.message && !responseJson.errors) {
      this.setState({ isLiked: responseJson.is_liked });
      if(!responseJson.is_liked && window.innerWidth < 768) {
        console.log("@==> dislike && ",window.innerWidth);
        const contactBox = document.querySelector('.contact-box');
        contactBox?.scrollIntoView({behavior:"smooth"});
      }
      return;
    }
    this.setState({ isLiked: null });
  }


  handleAccordien = (e: any, queNo: any) => {
    this.setState({isLiked: null});
    const allActiveEle: any = document.querySelector('.mob-question-box.active');
    const currEle = e.currentTarget.parentNode;
    if (allActiveEle === currEle) {
      currEle?.classList.remove('active');
    } else {
      allActiveEle?.classList.remove('active');
      this.getQuestionDetails(queNo);
      currEle?.classList.toggle('active');
    }
  }

  handleQueryChange = (e: any) => {
    this.setState({ searchQuery: e.target.value }, () => {
      if (!this.state.searchQuery) {
        this.getAllQueAns();
        this.setState({ allQueAnsMsg: "", is_error: false });
      }
    });
  }

  getUserAccount = async () => {
    const userToken = localStorage.getItem('Token');

    this.getUserAccountApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `bx_block_settings/settings/view_account`,
      token: userToken
    });
  }

  getAllQueAns = async () => {
    const { userId } = this.state;
    const node = userId ? `?account_id=${userId}` : ``;

    this.getAllQueAnsApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.defaultEndPoint + `${node}`
    });
  }

  getQuestionDetails = async (id: any) => {
    const { is_HeaderSidebar, userId } = this.state;
    const node = is_HeaderSidebar ? `?account_id=${userId}` : ``;

    this.getQueDetailsApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.defaultEndPoint + `/${id}${node}`
    });
  }

  handleQuerySubmit = async (e: any) => {
    e.preventDefault();
    this.setState({detailed_QueAns: "", isLiked: null});
    
    if (this.state.searchQuery) {
      this.searchQueryApiCallId = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.defaultEndPoint + `/search?query=${this.state.searchQuery}`
      });
    }
  }

  foundAction = (btnName: any) => {
    if (btnName === "like") {
      return true
    } else if (btnName === "dislike") {
      return false
    }
  }

  handleLike = async (btnName: any, queId: any) => {
    const userToken = localStorage.getItem('Token');
    const action = this.foundAction(btnName);

    const formdata = new FormData();
    formdata.append('data[attributes][question_answer_id]', queId.toString());
    formdata.append('data[attributes][is_liked]', `${action}`);

    this.likeApiCallId = await apiCallwithHeader({
      method: "POST",
      token: userToken,
      endPoint: configJSON.defaultEndPoint + `/like`,
      body: formdata
    });

  }
  // Customizable Area End
}
