import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../../../components/src/Apicall";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    history?: any;
    push?: any;
    location?: any;
    match?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    fullName: string;
    campanyName: string;
    email: string;
    mobileNo: string;
    designation: string;
    organizationSize: string;
    otpToken: string;
    otpError: string;
    errors: any;
    otp1: any;
    otp2: any;
    otp3: any;
    otp4: any;
    otp5: any;
    otp6: any;
    countdown: number;
    userData: any;
    is_otpResend: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    token: any;
    eventdd: any;
    // Customizable Area End
}

export default class MobileAccountRegistrationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    sendOtpApiCallId: any;
    verifyOtpApiCallId: any;
    createAccountApiCallId: any;
    timer: any;
    resendOtpApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            fullName: "",
            campanyName: "",
            email: "",
            mobileNo: "",
            designation: "",
            organizationSize: "",
            errors: {},
            otpToken: "",
            otpError: "",
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
            otp6: '',
            countdown: 30,
            userData: {},
            is_otpResend: false,
        };

        this.timer = null;
        this.receive = this.receive.bind(this);
        this.checkSendOtpError = this.checkSendOtpError.bind(this);
        this.startCountdown = this.startCountdown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.handleResendOtp = this.handleResendOtp.bind(this);
        this.sendOtp = this.sendOtp.bind(this);
        this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
        this.createAccount = this.createAccount.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (this.sendOtpApiCallId === apiRequestCallId) {
                if (responseJson.meta) {
                    const formData = JSON.stringify({
                        full_name: this.state.fullName,
                        company_name: this.state.campanyName,
                        designation: this.state.designation,
                        size_of_organization: this.state.organizationSize,
                        email: this.state.email,
                        full_phone_number: "91" + this.state.mobileNo
                    });
                    this.setState({ otpToken: responseJson.meta?.token }, () => {
                        localStorage.setItem("otpToken", responseJson.meta?.token);
                        localStorage.setItem("registerData", formData);
                        this.props.history.push('/verify_signup');
                    });
                }
                this.checkSendOtpError(responseJson);
            }

            if (this.verifyOtpApiCallId === apiRequestCallId) {
                this.verifyOtpApiProcess(responseJson);
            }

            if (this.createAccountApiCallId === apiRequestCallId) {
                this.createAccountApiProcess(responseJson);
            }

            if (this.resendOtpApiCallId === apiRequestCallId) {
                if (responseJson.meta) {
                    localStorage.setItem("otpToken", responseJson.meta?.token);
                }
                this.checkSendOtpError(responseJson);
            }
        }
        // Customizable Area End 
    }

    // Customizable Area Start
    checkSendOtpError = (responseJson: any) => {
        if (responseJson.errors) {
            const phoneError: any = {};
            if (responseJson.errors[0]?.account) {
                phoneError.mobileNoError = responseJson.errors[0]?.account
            } else {
                phoneError.mobileNoError = responseJson.errors[0]?.full_phone_number
            }
            this.setState({ ...this.state.errors, errors: phoneError });
        }
        if (responseJson.status === 500) {
            if (responseJson?.exception && responseJson?.exception.includes('is not a mobile number')) {
                const phoneError: any = {};
                phoneError.mobileNoError = "Please provide a valid mobile number"
                this.setState({ ...this.state.errors, errors: phoneError });
            }
        }
    }

    verifyOtpApiProcess = (responseJson:any) => {
        if (responseJson.data?.attributes?.activated) {
            this.createAccount();
        }
        if (responseJson.errors) {
            if (responseJson.errors[0]?.phone) {
                this.setState({ otpError: responseJson?.errors[0]?.phone });
            }
            if (responseJson.errors[0]?.pin) {
                this.setState({ otpError: responseJson?.errors[0]?.pin });
            }
        }
    }

    createAccountApiProcess = (responseJson:any) => {
        if (responseJson.data && responseJson.meta) {
            this.setState({ userData: responseJson.data }, () => {
                localStorage.clear();
                localStorage.setItem("Token", responseJson.meta?.token);
                this.props.history.push('/Home');
            })
        }
    }

    startCountdown = () => {
        this.timer = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState((prevState) => ({
                    countdown: prevState.countdown - 1,
                }));
            } else {
                clearInterval(this.timer);
                this.setState({ is_otpResend: false });
            }
        }, 1000);
    };

    handleNeedhelp = () => {
        this.props.history.push({
            pathname: "/helpAndFaq",
            state: {
                hsStatus: false
            }
        })
    }

    handleInputChange = (e: any) => {
        const inputState = e.target.name;
        this.setState({ ...this.state, [inputState]: e.target.value });
        if (inputState === "mobileNo") {
            localStorage.setItem("mobileNo", "91" + e.target.value)
        }
    }

    validateOrganizationSize = (organizationSize: number | string): string | null => {
        if (typeof organizationSize === "number") {
            return organizationSize > 0 ? "Organization size is required." : null;
        } else if (typeof organizationSize === "string") {
            return organizationSize.trim().length === 0 ? "Organization size is required." : null;
        } else {
            return null;
        }
    };

    handleSubmitData = (e: any) => {
        e.preventDefault();
        
        const { fullName, campanyName, mobileNo, designation, email, organizationSize } = this.state;
        const fieldsErrors:any = {
            organizationSizeError: this.validateOrganizationSize(organizationSize)
        };

        if (!fullName.trim().length) { 
            fieldsErrors.fullNameError = "Full name is required."; 
        }else if (fullName && !configJSON.firstNameRegex.test(fullName)) {
            fieldsErrors.fullNameError = "Please enter only alphabetic characters.";
        }
        if (!campanyName.trim().length) {
            fieldsErrors.campanyNameError = "Company name is required.";
        }
        if (!mobileNo.trim().length) {
            fieldsErrors.mobileNoError = "Mobile number is required.";
        }else if (mobileNo.toString().length < 10) {
            fieldsErrors.mobileNoError = "Invalid mobile number.";
        }
        if (!designation.trim().length) {
            fieldsErrors.designationError = "Designation is required.";
        }
        if (!email.length) {
            fieldsErrors.emailError = "Email is required.";
        }
        if (email && !configJSON.emailRegex.test(email)) {
            fieldsErrors.emailError = "Please provide a valid email.";
        }

        this.setState({ ...this.state.errors, errors: fieldsErrors }, () => {
            if (Object.keys(this.state.errors).length === 1) {
                if(this.state.errors.organizationSizeError === null) {
                    this.sendOtp();
                }
            }
        });
    }

    handleResendOtp = async () => {
        clearInterval(this.timer);
        this.setState({ 
            countdown: 30, 
            is_otpResend: true, 
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: ""
        }, () => {
            this.startCountdown();
        });

        const firstOtpBox:any = document.querySelector('#input1');
        firstOtpBox.focus();

        const phoneNo = localStorage.getItem('mobileNo');

        let httpData = {
            "data": {
                "attributes": {
                    "full_phone_number": phoneNo
                },
                "log_in": "false"

            }
        }

        this.resendOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            endPoint: `account_block/accounts/send_otps`,
            body: httpData
        })
    }

    sendOtp = async () => {
        let httpData = {
            "data": {
                "attributes": {
                    "full_phone_number": "91" + this.state.mobileNo
                },
                "log_in": "false"

            }
        }

        this.sendOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            endPoint: `account_block/accounts/send_otps`,
            body: httpData
        })
    }

    handleOtpSubmit = async (e: any) => {
        e.preventDefault();
        const { otp1, otp2, otp3, otp4, otp5, otp6 } = this.state;

        console.log("@===>", otp1 + otp2 + otp3 + otp4 + otp5 + otp6);

        if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
            this.setState({ otpError: "Invalid token" });
            return;
        }

        const otpToken = localStorage.getItem('otpToken');

        const httpData = {
            pin: otp1 + otp2 + otp3 + otp4 + otp5 + otp6
        }

        this.verifyOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            token: otpToken,
            endPoint: `account_block/accounts/sms_confirmations`,
            body: httpData
        });
    }

    createAccount = async () => {
        const userdata = JSON.parse(localStorage.getItem('registerData') || "");

        const httpData = {
            "data": {
                "attributes": {
                    "full_name": userdata.full_name,
                    "company_name": userdata.company_name,
                    "designation": userdata.designation,
                    "size_of_organization": userdata.size_of_organization,
                    "email": userdata.email,
                    "full_phone_number": userdata.full_phone_number,
                    "role_id": 2
                },
                "type": "sms_account"
            }
        }

        this.createAccountApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            endPoint: `account_block/accounts`,
            body: httpData
        })
    }
    // Customizable Area End  
}
