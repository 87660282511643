import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCallwithHeader } from "../../../components/src/Apicall";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    history?: any;
    push?: any;
    location?: any;
    match?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    fullName: string;
    descriptions: string;
    tag: string;
    messagesent:boolean;
 
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    token: any;
    eventdd: any;
    // Customizable Area End
}

export default class MobileAccountRegistrationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
 
    verifyOtpApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            fullName: "",
            descriptions: "",
            tag: "suggestion",
            messagesent:false,
        
        };

  
        this.receive = this.receive.bind(this);
        this.handleContactUs = this.handleContactUs.bind(this);
        

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

          
            if (this.verifyOtpApiCallId === apiRequestCallId) {
                console.log(responseJson);
                if(responseJson.message=="Successfully Submitted") {
                    this.setState({messagesent:true,fullName:'',descriptions:'',tag:'suggestion'})
                }
            }

          
        }
        // Customizable Area End 
    }

    // Customizable Area Start
    getToken=()=>{
        return localStorage.getItem('Token');
      }
    handleContactUs = async (e:any) => { 
        e.preventDefault();
        const otpToken = this.getToken()
    
        let formdata = JSON.stringify({
            "data": {
                "attributes": {
                    "name": this.state.fullName,
                    "description": this.state.descriptions,
                    "contact_type": this.state.tag
        
                }
            }
        })
        this.verifyOtpApiCallId = await apiCallwithHeader({
            contentType: "application/json",
            method: configJSON.httpPostMethod,
            token:otpToken,
            endPoint: `bx_block_contact_us/contacts`,
            body: formdata
        });
        
      }
 close=()=>{
    this.setState({messagesent:false})
 }
    // Customizable Area End  
}
