//@ts-nocheck
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute.web";
import PublicRoute from './PublicRoute.web';
import HomeScreen from "../../components/src/HomeScreen";


// Normal Routes
import Login from "../../blocks/mobile-account-login/src/MobileAccountLogin.web";
import VerifyLogin from "../../blocks/mobile-account-login/src/VerifyMobileLogin.web";
import Signup from "../../blocks/mobile-account-registration/src/MobileAccountRegistration.web";
import VerifySignup from "../../blocks/mobile-account-registration/src/VerifyMobileRegistration.web";
import HelpAndFaq from "../../blocks/helpcentre/src/HelpAndFaq.web";

// Private Routes
import HomePage from "../../blocks/landingpage/src/Homepage.web";
import AccountSettings from "../../blocks/Settings5/src/AccountSettings.web";
import ContactUs from "../../blocks/contactus/src/ContactUsScreen.web";

if (!HomeScreen.instance) {
    const defaultProps = {
        navigation: null,
        id: "HomeScreen"
    };
    const homeScreen = new HomeScreen(defaultProps);
}

const WebRoutes = () => {
    return (
        <Router>
            <Switch>
                <PublicRoute path="/" exact render={props => <Login {...props} />} />
                <PublicRoute path="/signup" exact render={props => <Signup {...props} />} />
                <PublicRoute path="/verify_login" exact render={props => <VerifyLogin {...props} />} />
                <PublicRoute path="/verify_signup" exact render={props => <VerifySignup {...props} />} />
                <Route path="/helpAndFaq" exact render={props => <HelpAndFaq {...props} />} />

                <PrivateRoute path="/Home" exact render={props => <HomePage {...props} />} />
                <PrivateRoute path="/settings" exact render={props => <AccountSettings {...props} />} />
                <PrivateRoute path="/contactus" exact render={props => <ContactUs  {...props} />} />
            </Switch>
        </Router>
    )
}

export default withRouter(WebRoutes);
