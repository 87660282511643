import React from "react";
import {withRouter} from "react-router-dom";
import {
    Button,
    Grid,
    TextField,
    // Customizable Area Start
    InputAdornment
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { logo } from "./assets"
import "../assets/css/login.css"
// Customizable Area End

import MobileAccountRegistrationController, {
    Props
} from "./MobileAccountRegistrationController.web";


export class MobileAccountRegistration extends MobileAccountRegistrationController {

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        if(localStorage.getItem('registerData')) {
            const userdata = JSON.parse(localStorage.getItem('registerData') || "");
            const phone = userdata.full_phone_number.slice(2);
            this.setState({
                fullName: userdata.full_name,
                campanyName: userdata.company_name,
                email: userdata.email,
                mobileNo: phone,
                designation: userdata.designation,
                organizationSize: userdata.size_of_organization
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            // Customizable Area Start
            <div id="Main">
                <div className="Login-Header">
                    <img src={logo} />
                    <Button
                        id="headerBtn"
                        className="btn-secondary btn-outlined"
                        onClick={() => this.props.history.push('/')}
                    >Login</Button>
                </div>
                <Grid container xs={12} className="registation-page-wrapper" id="Main">

                    <Grid item xs={12} sm={12} md={6} className="auth-common-sidebar"></Grid>

                    <Grid item xs={12} sm={12} md={6} className="auth-form-container">
                        <h2 className="form-title">Lets sign you up</h2>

                        <form className="registrationform" onSubmit={(e) => this.handleSubmitData(e)}>
                            <div className="inputfield">
                                <span className="input-label">Full Name*</span>
                                <TextField
                                    id="formInput1"
                                    placeholder="Enter Full Name"
                                    className="phoneinput"
                                    fullWidth
                                    type="text"
                                    value={this.state.fullName}
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "fullName"
                                    }}
                                />
                                {errors.fullNameError && <div className="validation-msg">{errors.fullNameError}</div>}
                            </div>

                            <div className="inputfield">
                                <span className="input-label">Company Name*</span>
                                <TextField
                                    id="formInput2"
                                    placeholder="Enter Company Name"
                                    className="phoneinput"
                                    fullWidth
                                    type="text"
                                    value={this.state.campanyName}
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "campanyName"
                                    }}
                                />
                                {errors.campanyNameError && <div className="validation-msg">{errors.campanyNameError}</div>}
                            </div>

                            <div className="inputfield">
                                <span className="input-label">Email*</span>
                                <TextField
                                    id="formInput3"
                                    placeholder="Enter Email"
                                    className="phoneinput"
                                    fullWidth
                                    type="text"
                                    value={this.state.email}
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "email"
                                    }}
                                />
                                {errors.emailError && <div className="validation-msg">{errors.emailError}</div>}
                            </div>

                            <div className="inputfield">
                                <span className="input-label">Phone*</span>
                                <TextField
                                    id="formInput4"
                                    placeholder="Enter Phone No"
                                    className="phoneinput"
                                    fullWidth
                                    type="number"
                                    value={this.state.mobileNo}
                                    onInput={(e: any) =>
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "mobileNo",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span>+91</span>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {errors.mobileNoError && <div className="validation-msg">{errors.mobileNoError}</div>}
                            </div>

                            <div className="inputfield">
                                <span className="input-label">Designation*</span>
                                <TextField
                                    id="formInput5"
                                    placeholder="Enter Designation"
                                    className="phoneinput"
                                    fullWidth
                                    type="text"
                                    value={this.state.designation}
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "designation"
                                    }}
                                />
                                {errors.designationError && <div className="validation-msg">{errors.designationError}</div>}
                            </div>

                            <div className="inputfield">
                                <span className="input-label">Size of Organization*</span>
                                <TextField
                                    id="formInput6"
                                    placeholder="Enter Size"
                                    className="phoneinput"
                                    fullWidth
                                    type="number"
                                    value={this.state.organizationSize}
                                    onChange={(e) => this.handleInputChange(e)}
                                    InputProps={{
                                        disableUnderline: true,
                                        name: "organizationSize"
                                    }}
                                />
                                {errors.organizationSizeError && <div className="validation-msg">{errors.organizationSizeError}</div>}
                            </div>

                            <div className="form-actions">
                                <div className="terms-msg">By proceeding you agree with <span>Terms & Condition</span></div>
                                <Button type="submit" className="btn-secondary">Sign up</Button>
                                <div className="ask-signup">
                                    Already have an account? <span id="bottomLink" onClick={() => this.props.history.push('/')}>Login</span>
                                </div>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        );
    }
}
//@ts-ignore
export default withRouter(MobileAccountRegistration);
