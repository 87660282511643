// Customizable Area Start
export const appLogo = require("../assets/common/app_logo.png");
export const peoplesIcon = require("../assets/common/peoples_icon.png");
export const searchIcon = require("../assets/common/search_icon.png");
export const notificationIcon = require("../assets/common/notification_icon.png");
export const homeIcon = require("../assets/common/Home_icon.png");
export const discoveryIcon = require("../assets/common/discovery_icon.png");
export const helpIcon = require("../assets/common/Help_icon.png");
export const contactIcon = require("../assets/common/contactUs_icon.png");
export const settingIcon = require("../assets/common/setting_icon.png");
export const sidebarToggleIcon = require("../assets/common/sidebar_toggle_icon.png");
export const optionUpIcon = require("../assets/common/optionUp_icon.png");
export const userIcon = require("../assets/common/user_icon.png");
export const logoutIcon = require("../assets/common/Logout_icon.png");
// Customizable Area End