import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import "../assets/css/HelpAndFaq.web.css";
import {
  Grid,
  OutlinedInput,
  InputAdornment,
  Box,
  Button,
} from "@material-ui/core";
// Customizable Area End
import HelpAndFaqController, { Props } from "./HelpAndFaqController";
import { searchIcon } from "../../landingpage/src/assets";
import { rightArrow } from "./assets";
import HeaderMain from "../../../components/src/Common/HeaderMain.web";
import SidebarMain from "../../../components/src/Common/SidebarMain.web";
import LikeIcon from "../../../components/src/Common/LikeIcon";

export class HelpAndFaq extends HelpAndFaqController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderContactBox = () => {
    return (<>
      {this.state.is_HeaderSidebar && this.state.isLiked===false ? (
        <>
          <Box className="contact-box">
            <span>Still need help?</span>
            <span>Contact us we will be back within 24 hours.</span>
            <Button className="btn-primary"
              id="contact-btn" 
              onClick={() => this.props.history.push('/contactus')}
            >Contact us</Button>
          </Box>
        </>
      ) : ''
      }
    </>);
  }
  // Customizable Area End

  render() {
    const { allQueAns, allQueAnsMsg, detailed_QueAns, is_error, is_HeaderSidebar, isLiked } = this.state;
    const likeClass = isLiked ? 'active' : '';
    const dislikeClass = isLiked === false ? 'active' : '';

    return (
      // Customizable Area Start
      <div id="Main">
        {is_HeaderSidebar && <HeaderMain />}
        <div className="middle-container">
          {is_HeaderSidebar && <SidebarMain />}
          <div className="content-wrapper help-Faq">
            <div className="page-title">Search for a question</div>

            <Grid container className="wrapper-main">
              <div className="faq-section">
                <form
                  id="search-que"
                  className="search-field"
                  onSubmit={(e) => this.handleQuerySubmit(e)}
                  encType="multipart/form-data"
                >
                  <OutlinedInput
                    id="search-input"
                    className="search-input"
                    placeholder="Search"
                    autoComplete="off"
                    onChange={(e) => this.handleQueryChange(e)}
                    endAdornment={
                      <InputAdornment position="end" component={"button"} type="submit" className="search-addon">
                        <img src={searchIcon} alt="" width={"25px"} />
                      </InputAdornment>
                    }
                  />
                </form>
                <div className="question-list">
                  {is_error ?
                    (<>
                      {allQueAnsMsg && <div className="empty-msg">{allQueAnsMsg}</div>}
                    </>) :
                    (<>
                      {allQueAns.length > 0 && allQueAns?.map((item: any) =>
                        <>
                          <div
                            className="question-box"
                            key={item.id + item.type}
                            onClick={() => this.getQuestionDetails(item?.attributes?.id)}
                          >
                            <div className="que-row">
                              <span className="que">{item.attributes?.question}</span>
                              <img src={rightArrow} width="20px" />
                            </div>
                          </div>
                          <div
                            className="mob-question-box"
                            key={item.id}
                          >
                            <div className="que-row" onClick={(e) => this.handleAccordien(e, item.attributes?.id)}>
                              <span className="que">{item.attributes?.question}</span>
                              <img src={rightArrow} width="20px" />
                            </div>
                            <div className="ans">
                              <p dangerouslySetInnerHTML={{ __html: detailed_QueAns?.answer }}></p>
                              {is_HeaderSidebar && <>
                                <div className="feedback-question">
                                  <h4 className="help-que">Did this answer your question?</h4>
                                </div>
                                <div className="like-dislike-container">
                                  <Button className={`like-btn ${likeClass}`} id="mob-like"
                                    onClick={() => this.handleLike("like", detailed_QueAns?.id)}
                                  >
                                    <LikeIcon />
                                  </Button>
                                  <Button className={`dislike-btn ${dislikeClass}`} id="mob-dislike"
                                    onClick={() => this.handleLike("dislike", detailed_QueAns?.id)}
                                  >
                                    <LikeIcon />
                                  </Button>
                                </div>
                              </>}
                            </div>
                          </div>
                        </>
                      )}
                    </>)
                  }
                </div>
                <this.RenderContactBox />
              </div>

              <div className="help-section">
                {detailed_QueAns && <>
                  <h4 className="help-que">{detailed_QueAns?.question}</h4>
                  <div className="help-ans"
                    dangerouslySetInnerHTML={{ __html: detailed_QueAns?.answer }}
                  ></div>

                  {is_HeaderSidebar && <>
                    <div className="feedback-question">
                      <h4 className="help-que">Did this answer your question?</h4>
                    </div>

                    <div className="like-dislike-container">
                      <Button className={`like-btn ${likeClass}`} id="des-like" onClick={() => this.handleLike("like", detailed_QueAns?.id)}>
                        <LikeIcon />
                      </Button>
                      <Button className={`dislike-btn ${dislikeClass}`} id="des-dislike" onClick={() => this.handleLike("dislike", detailed_QueAns?.id)}>
                        <LikeIcon />
                      </Button>
                    </div>
                  </>}
                </>}
              </div>
            </Grid>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}
// @ts-ignore
export default withRouter(HelpAndFaq);
