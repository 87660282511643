import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../../../components/src/Apicall";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    history?: any;
    push: any;
    location?: any;
    match: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileNo: any;
    mobileError: string;
    otpToken: string;
    countdown: number;
    otp1: any;
    otp2: any;
    otp3: any;
    otp4: any;
    otp5: any;
    otp6: any;
    otpError: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    token: any;
    eventdd: any;
    // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    sendOtpApiCallId: any;
    verifyOtpApiCallId:any;
    timer: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            mobileNo: "",
            mobileError: "",
            otpToken: "",
            countdown: 30,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            otpError: ""
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.startCountdown = this.startCountdown.bind(this);
        this.handleChangeNo = this.handleChangeNo.bind(this);
        this.handleResendOtp = this.handleResendOtp.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.sendOtp = this.sendOtp.bind(this);
        this.verifyOtp = this.verifyOtp.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (this.sendOtpApiCallId === apiRequestCallId) {
                if (responseJson.meta) {
                    this.setState({ otpToken: responseJson.meta.token }, () => {
                        if(this.props.location.pathname === "/"){
                            this.props.history.push({
                                pathname: "verify_login",
                                state: {
                                    mobile: this.state.mobileNo,
                                    otpToken: this.state.otpToken,
                                }
                            });
                        }
                    });
                }
                this.checkSendOtpApiErrors(responseJson);
            }

            if(this.verifyOtpApiCallId === apiRequestCallId) {
                this.verifyOtpApiProcess(responseJson);
            }
        }
        // Customizable Area End 
    }

    // Customizable Area Start
    verifyOtpApiProcess = (responseJson:any) => {
        if(responseJson.meta){
            localStorage.clear();
            localStorage.setItem("Token", responseJson.meta?.token);
            this.props.history.push('/Home');
        }
        if (responseJson.errors) {
            if (responseJson.errors[0]?.phone) {
                this.setState({ otpError: responseJson.errors[0].phone });
            }
            if (responseJson.errors[0]?.pin) {
                this.setState({ otpError: responseJson.errors[0].pin });
            }
        }
    }

    checkSendOtpApiErrors = (responseJson:any) => {
        if (responseJson.errors) {
            this.setState({ mobileError: responseJson.errors[0]?.account });
        }
    }

    handleNeedhelp = () => {
        this.props.history.push({
            pathname: "/helpAndFaq",
            state: {
                hsStatus: false
            }
        })
    }

    startCountdown = () => {
        this.timer = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState((prevState) => ({
                    countdown: prevState.countdown - 1,
                }));
            } else {
                clearInterval(this.timer);
            }
        }, 1000);
    };

    handleChangeNo = () => {
        this.props.history.push({
            pathname: "/",
            state: {
                phone : this.state.mobileNo
            }
        })
    }

    handleResendOtp = () => {
        clearInterval(this.timer);
        this.setState({ 
            countdown: 30, 
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: ""
        }, () => {
            this.startCountdown();
            this.sendOtp();
        });
        const otpFirstBox:any = document.querySelector("#otpInput1");
        otpFirstBox.focus();
    }

    handleSubmitData = (e: any) => {
        e.preventDefault();
        localStorage.setItem('loginState', this.state.mobileNo);
        if (this.state.mobileNo.length === 0) {
            this.setState({ mobileError: "Mobile number is required." });
        }else if(this.state.mobileNo && this.state.mobileNo.length < 10){
            this.setState({ mobileError: "Please provide a valid phone number." });
        }
        else {
            this.sendOtp();
        }
    }

    sendOtp = async () => {
        const httpData = {
            "data": {
                "attributes": {
                    "full_phone_number": "91" + this.state.mobileNo
                },
                "log_in": "true"

            }
        }

        this.sendOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            endPoint: `account_block/accounts/send_otps`,
            body: httpData
        });
    }

    verifyOtp = async (e:any) => {
        e.preventDefault();
        const {otp1, otp2, otp3, otp4, otp5, otp6} = this.state;

        if(!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
            this.setState({otpError: "Entered OTP is invalid"});
            return;
        }

        const httpData = {
            pin: otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
        }

        this.verifyOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.postApiMethodType,
            token: this.state.otpToken,
            endPoint: `account_block/accounts/sms_confirmations`,
            body: httpData
        });
    }
    // Customizable Area End
}
