import React from "react";
import {
    OutlinedInput,
    Button,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
     DialogContentText, DialogContent
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ContactusController, { Props } from "./ContactUsController.web";
import HeaderMain from "../../../components/src/Common/HeaderMain.web";
import SidebarMain from "../../../components/src/Common/SidebarMain.web";
import "../assets/css/ContactUsScreen.web.css";



export default class ContactUsScreen extends ContactusController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Customizable Area Start
            <>
              <HeaderMain/> 
                <div className="middle-container" id="Main">
                    <SidebarMain />
                    <div className="content-wrapper contact-us-page">
                        <div className="inner-block">
                            <div className="page-title">Love to hear from you,<br />Get in touch.</div>

                            <form className="contact-form" onSubmit={(e) => this.handleContactUs(e)}>
                                <RadioGroup
                                    className="question-type-options"
                                    name="question-type-options"
                                    defaultValue={"suggestion"}
                                    value={this.state.tag}
                                    onChange={(e) => this.setState({ tag: e.target.value })}
                                    id="radioGroup"
                                >
                                    <FormControlLabel className="question-type-opt" value="suggestion" control={<Radio size="small"  />} label="Suggestion" />
                                    <FormControlLabel className="question-type-opt" value="query" control={<Radio size="small" />} label="Query" />
                                </RadioGroup>

                                <div className="input-field">
                                    <div className="lable">Name*</div>
                                    <OutlinedInput
                                        className="form-input"
                                        id="form-input"
                                        data-test-id="userName"
                                        placeholder="Please Enter your Name"
                                        value={this.state.fullName}
                                        onChange={(e) => this.setState({ fullName: e.target?.value })}
                                        required
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </div>
                                <div className="input-field">
                                    <div className="lable">Message*</div>
                                    <textarea
                                        className="form-input"
                                        id="form-input"
                                        placeholder="Message"
                                        rows={12}
                                        data-test-id="description"
                                        required
                                        maxLength={1500}
                                        value={this.state.descriptions}
                                        onChange={(e) => this.setState({ descriptions: e.target?.value })}
                                    />
                                </div>
                                <Button type="submit" id="btnedit" className="btn-primary submit-btn">send</Button>
                            </form>
                            <Dialog
                                open={this.state.messagesent}
                               
                                className="contect-success-dialog"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >

                                <Button id="btnnon" className="close-btn">
                                    <CloseIcon onClick={this.close} data-test-id="editImage" />
                                </Button>

                                <DialogContent className="dialog-content">
                                    <h3>Thank You</h3>
                                    <DialogContentText id="alert-dialog-description">
                                        Your Message has been recieved and will be <br></br>
                                        contacting you shortly to follow up
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </>
            // Customizable Area End
        )
    }
}
