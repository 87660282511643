export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/down_arrow.png");
export const likeIcon = require("../assets/like.png");
