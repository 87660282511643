Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.firstNameRegex = /^[a-zA-Z\s]*$/;
// Customizable Area End