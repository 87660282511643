import { logo } from "../../../blocks/mobile-account-login/src/assets";
import { Grid, Button } from "@material-ui/core";
import React from "react";


export const VerifyOtpHeader = () => {
    return (
        <div className="Login-Header">
            <img src={logo} />
        </div>
    );
}

export const VerifyOtpSidebar = () => {
    return (
        <Grid item xs={12} sm={12} md={6} className="auth-common-sidebar"></Grid>
    );
}