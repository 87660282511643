import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export const configJSON = require("./config");
import { apiCall } from "../../../components/src/Apicall";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    history?: any;
    push: any;
    location?: any;
    match: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    value: any;
    open: boolean;
    user_image: any;
    getaccount: any;
    emailpopup: boolean;
    otp1: any;
    otp2: any;
    otp3: any;
    otp4: any;
    otp5: any;
    otp6: any;
    otpError: any;
    countdown: number;
    subscriptionType: string;
    subscriptionStart: string,
    subscriptionEnd: string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    token: any;
    eventdd: any;

    // Customizable Area End
}

export default class AccountSettingsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    profileupdateCallId: any;
    closeaccountApiCallId: any;
    getaccountApiCallId: any;
    sendEmailOtpApiCallId: any;
    verifyOtpApiCallId: any
    removeApiCallId: any
    subscriptionApiCallId: any;
    timer: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            value: "1",
            open: false,
            user_image: '',
            getaccount: '',
            emailpopup: false,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            otpError: "",
            countdown: 30,
            subscriptionType: "",
            subscriptionStart: "",
            subscriptionEnd: "",
        };

        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    async componentDidMount() {
        // Customizable Area Start      
        this.getAccount();
        this.getSubscription();
        // Customizable Area End
    }

    async componentWillUnmount() {
        clearInterval(this.timer);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            console.log(apiRequestCallId);
            console.log(responseJson);
            console.log(errorReponse);
            if (this.profileupdateCallId === apiRequestCallId) {
                console.log(responseJson, "Blogs Api Response")

            }
            if (this.closeaccountApiCallId === apiRequestCallId) {

                this.closeaccoutnfun(responseJson);
            }
            if (this.getaccountApiCallId === apiRequestCallId) {
                console.log(responseJson, "Blogs Api Response")
                this.setState({ getaccount: responseJson.data.attributes, user_image: responseJson.data.attributes.profile_pic });
            }
            if (this.sendEmailOtpApiCallId === apiRequestCallId) {
                console.log(responseJson, "Blogs Api Response")
                this.setState({ emailpopup: true })
                this.startCountdown();
            }
            if (this.verifyOtpApiCallId === apiRequestCallId) {

                this.otpfuncone(responseJson);

            }
            if (this.removeApiCallId === apiRequestCallId) {

                this.setState({ user_image: "" })
            }
            this.subscriptionApiProcess(responseJson, apiRequestCallId);
        }

        // Customizable Area End 
    }

    handleChange = (_: any, value: any) => {
        this.setState({
            value,
        });
    };

    // Customizable area start
    otpfuncone = (responseJson: any) => {
        if (responseJson.errors) {

            this.setState({ otpError: responseJson.errors[0]?.pin });

        }
        if (responseJson.message?.includes("Email Verified Successfully")) {

            this.setState({ emailpopup: false }, () => {
                this.getAccount();
            })
        }
    }
    closeaccoutnfun = (responseJson: any) => {
        if (responseJson.message == "Account Deleted successfully!") {

            localStorage.clear();
            this.props.history.push('/');
        }
    }
    subscriptionApiProcess = (responseJson: any, apiRequestCallId: any) => {
        if (this.subscriptionApiCallId === apiRequestCallId) {
            if (!responseJson.message && !responseJson.errors) {
                this.setState({
                    subscriptionType: responseJson.name,
                    subscriptionStart: this.formatDate(responseJson.created_on),
                    subscriptionEnd: responseJson.valid_till ? this.formatDate(responseJson.valid_till) : "-",
                });
            }
        }
    }
    custURL = (data: any) => {
        const reader: any = new window.FileReader();
        reader.addEventListener("load", () => {
            this.setState({ user_image: reader.result });

        });
        reader.readAsDataURL(data);
        this.getimageUpdate(data);
    };
    startCountdown = () => {
        this.timer = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState((prevState) => ({
                    countdown: prevState.countdown - 1,
                }));
            } else {
                clearInterval(this.timer);
            }
        }, 1000);
    };
    handleResendOtp = () => {
        clearInterval(this.timer);
        this.setState({
            countdown: 30,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            otpError: "",
        }, () => {
            this.startCountdown();
            this.sendEmailOtp();
        });
        const otpFirstBox: any = document.querySelector("#OTPInput1");
        otpFirstBox.focus();
    }
    getToken = () => {
        return localStorage.getItem('Token');
    }
    getimageUpdate = async (data: any) => {
        console.log(data);

        const user = this.getToken()
        let header = new Headers();
        header.append("token", `${user}`);
        let formdata = new FormData();
        formdata.append("image", data);

        const httpBody = formdata

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileupdateCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_settings/settings/upload_profile_picture`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    removeimage = async (e: any) => {
        e.preventDefault();
        const otpToken = this.getToken();
        this.removeApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.httpDeleteMethod,
            endPoint: configJSON.removeimgendpoint,
            token: otpToken
        });
    }
    closeAccount = async (e: any) => {
        e.preventDefault();
        const otpToken = this.getToken();
        this.closeaccountApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.httpDeleteMethod,
            endPoint: configJSON.deleteendpoint,
            token: otpToken
        });
    }
    getAccount = async () => {

        const otpToken = this.getToken();
        this.getaccountApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getaccountendpoint,
            token: otpToken
        });
    }
    sendEmailOtp = async () => {

        const otpToken = this.getToken();
        this.sendEmailOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationGetApiMethodType,
            endPoint: configJSON.sendemailotp,
            token: otpToken
        });
    }
    verifyOtp = async (e: any) => {
        e.preventDefault();

        const otpToken = this.getToken();
        const { otp1, otp2, otp3, otp4, otp5, otp6 } = this.state;

        if (!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6) {
            this.setState({ otpError: "Entered OTP is invalid" });
            return;
        }

        const httpData = {
            pin: otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
        }

        this.verifyOtpApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationGetApiMethodType,
            token: otpToken,
            endPoint: `bx_block_settings/settings/verify_email_otp`,
            body: httpData
        });
    }
    inputFocus = (elmnt: any) => {
        const optInputs: any = document.querySelectorAll('.otpInput');

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1 && optInputs[next]) {
                optInputs[next].focus();
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6 && optInputs[next]) {
                optInputs[next].focus();
            }
        }
    }

    getSubscription = async () => {
        const userToken = this.getToken();
        this.subscriptionApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: "GET",
            token: userToken,
            endPoint: `bx_block_subscriptions/subscription/active_subscription`,
        });
    }

    formatDate = (dateString: string) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const [year, month, day] = dateString.split('-').map(Number);

        let suffix;
        if (day === 1 || day === 21 || day === 31) {
            suffix = "st";
        } else if (day === 2 || day === 22) {
            suffix = "nd";
        } else if (day === 3 || day === 23) {
            suffix = "rd";
        } else {
            suffix = "th";
        }

        const formattedDate = `${day}<sup>${suffix}</sup> ${months[month - 1]} ${year}`;

        return formattedDate;
    }
    // Customizable Area End

}
