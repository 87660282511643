import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;

    const header = {
        "Content-Type": contentType,
        "token": token ? token : ""
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};
export const apiCallwithHeader = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;


    let header = new Headers();
    header.append("token", token);
    contentType && header.append("Content-Type", contentType)
    
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};