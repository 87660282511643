Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationGetApiMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.httpPutMethod="PUT";
exports.httpDeleteMethod="DELETE";
exports.deleteendpoint="bx_block_settings/settings/delete_account";
exports.getaccountendpoint="bx_block_settings/settings/view_account";
exports.sendemailotp="bx_block_settings/settings/email_otp";
exports.removeimgendpoint="bx_block_settings/settings/remove_profile_picture";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End